// https://ag-grid.com/react-data-grid/global-style-customisation-sass/
@use '~ag-grid-community/styles' as ag;

// Include the AG Grid CSS bundle.
// Define our own "runway" theme.
@include ag.grid-styles(
  (
    theme: runway,
    extend-theme: alpine,
    --ag-borders: false,
    --ag-row-height: 32px,
  )
);

// Override theme options
.ag-theme-runway {
  --ag-borders: none;
  --ag-foreground-color: var(--chakra-colors-text-primary);
  --ag-header-cell-hover-background-color: transparent;
  --ag-odd-row-background-color: var(--chakra-colors-surface-background);
  --ag-background-color: var(--chakra-colors-surface-background);
  --ag-selected-row-background-color: var(--chakra-colors-selection-100);
  --ag-range-selection-background-color: var(--chakra-colors-selection-100);
  // Remove min-height on scrollable column view so scroll bar
  // will be just below visible rows
  .ag-layout-auto-height .ag-center-cols-viewport,
  .ag-layout-auto-height .ag-center-cols-container {
    min-height: initial;
  }

  .ag-header {
    background-color: transparent;
    margin-bottom: -1px;
    overflow: visible;
  }

  .name-cell {
    .ag-header-cell-resize {
      background-color: var(--chakra-colors-border-dark);
      right: 0;
      width: 1px;
    }

    .ag-header-cell-comp-wrapper {
      align-items: flex-end;
    }
  }

  .ag-header-cell {
    border-bottom: 1px solid var(--chakra-colors-border-dark);
    overflow: visible;

    &.menu-header-cell {
      border-bottom: 0;
    }

    &.group-header-child-cell {
      border-top: 1px solid var(--chakra-colors-border-dark) !important;

      .ag-header-cell-resize {
        display: none;
      }
    }

    &.group-header-child-cell:not(.ag-pinned-left-header .ag-header-cell:last-child) {
      border-right: 1px solid var(--chakra-colors-border-light) !important;
    }

    &.last-cell-in-group:not(.ag-pinned-left-header .ag-header-cell:last-child) {
      border-right: 1px solid var(--chakra-colors-border-dark) !important;
    }
  }

  // This is not a default AG Grid class – we added it to handle hover on non-read-only header cells
  .ag-header-hover {
    &:hover {
      background-color: var(--chakra-colors-gray-50);
      transition: 0.1s ease-in;
    }
  }

  .ag-header-cell.options-cell,
  .ag-header-cell.add-cell {
    border-bottom: 0;
  }

  // Hide header resize handle until hover
  .ag-header-cell-resize {
    width: 5px;

    &::after {
      // Hide AG grid's default resize handle
      background-color: transparent;
    }

    &:hover {
      background-color: #4b9ef4;
    }
  }

  .ag-header-cell.ag-header-span-height:not(:last-child) .ag-header-cell-resize {
    height: 32px;
    top: 32px;
  }

  // Always show the resize handle on the last pinned column header
  .ag-pinned-left-header {
    overflow: visible;

    /* stylelint-disable selector-max-compound-selectors */
    .ag-header-cell:last-child .ag-header-cell-resize {
      background-color: var(--chakra-colors-border-dark);
      right: 0;
      width: 1px;

      &:hover {
        background-color: #4b9ef4;
        right: -2px;
        width: 5px;
      }
    }

    .ag-header-group-cell:last-child .ag-header-cell-resize {
      background-color: var(--chakra-colors-border-dark);
      right: 0;
      width: 1px;

      &:hover {
        background-color: #4b9ef4;
        right: -2px;
        width: 5px;
      }
    }

    /* stylelint-enable selector-max-compound-selectors */
  }

  .property-header-cell:not(.group-header-child-cell) {
    background: var(--chakra-colors-surface-background);

    &:hover {
      background-color: var(--chakra-colors-gray-50) !important;
    }
  }

  .ag-header-highlight-before {
    border-left: 3px solid #4b9ef4 !important;
  }

  .ag-row {
    border: 0;
  }

  .ag-row-focus {
    background-color: var(--chakra-colors-selection-100);
  }

  .ag-row-group {
    border: 0;
  }

  .ag-cell {
    align-items: center;
    border-bottom: 1px solid var(--chakra-colors-border-light);
    border-collapse: collapse;
    border-right: 1px solid var(--chakra-colors-border-light);
    display: flex;
    font-feature-settings: 'tnum', 'lnum';
    font-size: var(--chakra-fontSizes-xs);
    line-height: var(--chakra-lineHeights-6);
    padding: 0;

    &.last-close-cell:not(.ag-cell-range-selected) {
      border-right: 1px solid var(--chakra-colors-border-dark);
    }

    &.menu-cell {
      border: 0;
    }

    &.name-cell {
      border-right: 1px solid var(--chakra-colors-border-dark);
    }

    &.last-cell-in-group {
      border-right: 1px solid var(--chakra-colors-gray-400) !important;
    }

    &.last-row-in-group {
      border-bottom: 1px solid var(--chakra-colors-gray-400) !important;
    }

    &.drill-in-name {
      background: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 4px,
        color(from #81caff srgb r g b / 0.15) 4px,
        color(from #81caff srgb r g b / 0.15) 6px
      );
    }
  }

  .ag-row.first-row-in-group
    .ag-cell:not(.options-cell):not(.add-cell):not(.ag-cell-focus):not(.menu-cell) {
    border-top: 1px solid var(--chakra-colors-border-light);
  }

  .ag-row.dragged-row {
    cursor: move;
    opacity: 0.25;
  }

  .ag-cell.options-cell,
  .ag-cell.add-cell {
    background: var(--chakra-colors-surface-background);
    border: 0;
  }

  .ag-cell.segment-by-cell:not(.ag-cell-range-selected) {
    border-right: 0;
  }

  .ag-cell-focus {
    // Keep the border and just change its color to prevent it from moving contents around
    border-color: var(--chakra-colors-selection-500);
    box-shadow: 0 0 0 0.5px var(--chakra-colors-selection-500) inset;
  }

  .ag-cell-focus.ag-cell-range-selected {
    .driver-name-cell-left-border {
      height: calc(100% - 3px);
      top: 1.5px;
    }
  }

  // Ensures that the options gripper is not highlighted.
  .ag-cell-focus.ag-cell.options-cell {
    background: var(--chakra-colors-surface-background) !important;
  }

  // Ensure cells are not highlighted.
  // Hard border for name & keys.
  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
    border-right: 1px solid var(--chakra-colors-border-dark);
  }

  .ag-cell-last-left-pinned.ag-cell-range-selected:not(.ag-cell-focus) {
    border-left: 1px solid var(--chakra-colors-selection-500);
  }

  &.ag-theme-runway-custom-header {
    .ag-header-cell,
    .ag-header-group-cell {
      padding-left: 0;
      padding-right: 0;
    }

    // Keep in sync with pageSelector.ts
    &.sidebar-collapsed {
      .ag-body-horizontal-scroll .ag-horizontal-left-spacer {
        margin-left: 96px;
        margin-right: -96px;
      }
    }

    &.sidebar-expanded {
      .ag-body-horizontal-scroll .ag-horizontal-left-spacer {
        margin-left: 56px;
        margin-right: -56px;
      }
    }

    &.non-desktop {
      .ag-body-horizontal-scroll .ag-horizontal-left-spacer {
        margin-left: 12px;
        margin-right: -12px;
      }
    }
  }

  .ag-popup-child:not(.ag-tooltip-custom) {
    box-shadow: none !important;
  }

  .ag-overlay-loading-wrapper {
    align-items: start;
    margin-bottom: 32px;

    .ag-overlay-loading-center {
      border: 1px solid var(--chakra-colors-border-light);
    }
  }

  .ag-menu {
    background: none !important;
    z-index: 1000;
  }

  .ag-menu-list {
    padding: 20px;
    padding-top: 1px;
  }
}

.ag-theme-alpine {
  --ag-font-family: var(--chakra-fontFamily-body);
  --ag-header-column-resize-handle-height: 100%;
}

.unclickable-cell {
  pointer-events: none;

  &.ag-cell-focus {
    box-shadow: none;
  }
}

.menu-cell {
  // Don't highlight this cell if it is in a selected row or a cell range
  background-color: var(--ag-background-color) !important;

  &.ag-cell-focus {
    box-shadow: none;
  }

  &.ag-cell-focus,
  &.ag-cell-no-focus {
    border-width: 0 !important;
  }

  &.ag-cell:focus {
    border-width: 0 !important;
    outline: none;
  }
}
